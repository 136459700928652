import request from '@/utils/request'
import { iResponse } from '@/services/types'

interface ProjectListParams {
  page: number
  pageSize: number
  name: string | undefined,
  project_id: string | undefined,
}

interface ProjectAddParams {
  pid?: number | string
  name: string
  mode: string
  agent_ids: string
  scan_id: number
}

export default () =>
  new (class {
    dashboardByProjectGroup(params: any,project_group_id:any): Promise<iResponse> {
      return request.get(`/dashboard/project/${project_group_id}`, { params })
    }
    dashboard(params: any): Promise<iResponse> {
      return request.get('/dashboard/all', { params })
    }
    // 项目列表ProjectListParams
    projectList(params: any): Promise<iResponse> {
      return request.get('/projects', { params })
    }

    // 解除项目关联 参数 project_id 、 application_id
    unbindProjects(params: any): Promise<iResponse> {
      return request.post('/project/unbind', params)
    }

    versionList(id: any): Promise<iResponse> {
      return request.get(`/project/version/list/${id}`)
    }

    versionAdd(params: any): Promise<iResponse> {
      return request.post(`/project/version/add`, params)
    }

    versionEdit(params: any): Promise<iResponse> {
      return request.post(`/project/version/update`, params)
    }

    versionCurrent(params: any): Promise<iResponse> {
      return request.post(`/project/version/current`, params)
    }

    versionDelete(params: any): Promise<iResponse> {
      return request.post(`/project/version/delete`, params)
    }

    //新增项目 编辑
    projectAdd(params: ProjectAddParams): Promise<iResponse> {
      return request.post('/project/add', params)
    }

    getEngineList(id: string): Promise<iResponse> {
      return request.get(`/project/engines/${id}`)
    }

    // 项目详情
    projectDetail(id: string): Promise<iResponse> {
      return request.get(`/project/${id}`)
    }

    // 删除项目
    projectDelete(params: { id: number }): Promise<iResponse> {
      return request.post('/project/delete', params)
    }

    //项目详情 - 图标概括
    projectsSummary(id: string, version?: string): Promise<iResponse> {
      return request.get(`/projects/summary/${id}`, {
        params: {
          version_id: version,
        },
      })
    }
    //项目详情 - 图标概括
    agentSummary(id: string, version?: string): Promise<iResponse> {
      return request.get(`/agent/summary/${id}`, {
        params: {
          version_id: version,
        },
      })
    }
    //项目详情 - api列表
    searchApi(params: any): Promise<iResponse> {
      return request.post('/api_route/search', params, {
        baseURL: '/api/v3'
      })
    }

    detailApi(id: any): Promise<iResponse> {
      return request.get(`/api_route/${id}/detail`, {
        baseURL: '/api/v3'
      })
    }

    //项目详情 - 覆盖率
    coverRate(params: any): Promise<iResponse> {
      return request.get('/api_route/cover_rate', { params, baseURL: '/api/v3' })
    }

    //项目详情 - api 查看详情
    relationrequest(params: any): Promise<iResponse> {
      return request.get('/api_route/relationrequest', { params, baseURL: '/api/v3' })
    }

    async_add(params: any): Promise<iResponse> {
      return request.post(`/project/report/async_add`, params)
    }

    exportList(params: any): Promise<iResponse> {
      return request.get(
        `project/report/list?page=${params.page}&page_size=${params.pageSize}&pid=${params.pid}`
      )
    }

    exportDelete(params: any): Promise<iResponse> {
      return request.post(`/project/report/delete`, params)
    }

    req_headers(params: any): Promise<iResponse> {
      return request.get(`/project/${params.id}/api_test/req_headers`)
    }

    // 报告
    reportList(data: any): Promise<iResponse> {
      return request.post('/project/report/list', data)
    }
    // 报告删除
    reportDelete(data: any): Promise<iResponse> {
      return request.post('/project/report/delete', data)
    }

    reportSummary(pid: any): Promise<iResponse> {
      return request.get(`/dashboard/report/summary/${pid}`)
    }

    vulLevels(): Promise<iResponse> {
      return request.get(`/package_vul_level`,{ baseURL: process.env.VUE_APP_BASE_API_SCA_V2 })
    }

    strategyTypes(): Promise<iResponse> {
      return request.get(`/strategy/types`)
    }

    typeSummary(data: any): Promise<iResponse> {
      return request.post(`/dashboard/report/type_summary/${data.pid}`, data)
    }

    asyncAdd(data: any): Promise<iResponse> {
      return request.post(`/project/report/async_add`, data)
    }

    reportAdd(data: any): Promise<iResponse> {
      return request.post(`/project/report/async_add`, data)
    }

    redo(id: any): Promise<iResponse> {
      return request.post(`/project/report/redo/${id}`)
    }
    getRecognizeRule(data: any): Promise<iResponse> {
      return request.get(
        `/project/recognize_rule?page=${data.page}&page_size=${data.pageSize}&project_id=${data.project_id}&rule_type=${data.rule_type}`
      )
    }
    postRecognizeRule(data: any): Promise<iResponse> {
      return request.post(`/project/recognize_rule`, data)
    }
    deleteRecognizeRule(params: any): Promise<iResponse> {
      return request.delete(`/project/recognize_rule`, { data: params })
    }
    getRecognizeRuleById(id: any): Promise<iResponse> {
      return request.get(`/project/recognize_rule/${id}`)
    }
    putRecognizeRuleById(data: any): Promise<iResponse> {
      return request.put(`/project/recognize_rule/${data.rule_id}`, {
        rule_detail: data.rule_detail,
        project_id: data.project_id,
        rule_type: data.rule_type,
      })
    }

    getUsers(params: any): Promise<iResponse> {
      return request.get(`/project/unauthed_user_list`, {
        params,
        baseURL: '/api/v2'
      })
    }

    editAuthedUsers(id: any, data: any): Promise<iResponse> {
      return request.post(`/project/${id}/authed_user`, data, {
        baseURL: '/api/v2'
      })
    }

    getAuthedUsers(id: any): Promise<iResponse> {
      return request.get(`/project/${id}/authed_user`, {
        baseURL: '/api/v2'
      })
    }

    getProjectToken(id: any): Promise<iResponse> {
      return request.get(`/project/${id}/token`)
    }

    getTypeSummary(id: any, params: any): Promise<iResponse> {
      return request.get(`/projects/summary/${id}/type_summary`, {
        params
      })
    }
  })()
